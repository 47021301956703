import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Customer, CustomersValue } from '../models';

export const customersActions = createActionGroup({
    source: 'Customers',
    events: {
        'Get Customers': props<{ params: CustomersValue }>(),
        'Get Customers Success': props<{ items: Customer[]; totalItems: number }>(),
        'Get Customers Error': emptyProps(),

        Clear: emptyProps(),
    },
});
