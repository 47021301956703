import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomersState } from '../reducers/customers.reducer';
import { CUSTOMERS_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<CustomersState>(CUSTOMERS_FEATURE_KEY);

const selectLoading = createSelector(selectState, (state: CustomersState) => state.loaded === null);
const selectCustomers = createSelector(selectState, (state: CustomersState) => state.customers);
const selectTotalItems = createSelector(selectState, (state: CustomersState) => state.totalItems);

export { selectLoading, selectCustomers, selectTotalItems };
