import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiListingResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { Customer, CustomerDto, CustomersValue, CustomersValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getCustomers(params: CustomersValue) {
        return this.httpClient.get<ApiListingResponse<CustomerDto[]>>(`api/v1/customers`, { params: new CustomersValueDto(params).params }).pipe(
            map((response) => ({
                items: response.data.map((customerDto) => new Customer(customerDto)),
                totalItems: response.pagination.totalItems,
            })),
        );
    }
}
