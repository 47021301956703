import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap } from 'rxjs';
import { HttpService } from '../services/http.service';
import { customersActions } from '../actions/customers.actions';

@Injectable()
export class CustomersEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getCustomers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(customersActions.getCustomers),
            switchMap(({ params }) => {
                return this.httpService.getCustomers(params).pipe(
                    map(({ items, totalItems }) => customersActions.getCustomersSuccess({ items, totalItems })),
                    catchError(() => of(customersActions.getCustomersError())),
                );
            }),
        );
    });
}
