import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CUSTOMERS_FEATURE_KEY } from './keys';
import { customersReducer } from './reducers/customers.reducer';
import { CustomersEffects } from './effects/customers.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(CUSTOMERS_FEATURE_KEY, customersReducer), EffectsModule.forFeature([CustomersEffects])],
})
export class CustomersStoreModule {}
