import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectCustomers, selectLoading, selectTotalItems } from '../selectors/customers.selector';
import { customersActions } from '../actions/customers.actions';
import { Customer, CustomersValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class CustomersFacade {
    private readonly store = inject(Store);

    loading$ = this.store.select(selectLoading);
    totalItems$ = this.store.select(selectTotalItems);
    customers$ = this.store.select(selectCustomers).pipe(filter((data): data is Customer[] => data !== null));

    getCustomers(params: CustomersValue): void {
        this.store.dispatch(customersActions.getCustomers({ params }));
    }

    clear(): void {
        this.store.dispatch(customersActions.clear());
    }
}
